<template>
    <div class="bg-white rounded mb-2 shadow border-b overflow-hidden">
        <div class="flex">
            <div class="bg-primary px-1 flex justify-content items-center">
                <img v-bind:src="getIcon()" class="block w-menu" />
            </div>
            <div class="flex-grow leading-normal pr-4">
                <div class="text-sm font-bold pl-2 pt-2">
                    <span v-text="model"></span>
                </div>
                <div class="text-xxs pl-2 pb-2 flex mt-1">
                    <div class="inline bg-blue leading-loose rounded shadow text-white flex mr-2">
                        <div v-text="lang('operating.system')" class="px-1 font-bold papsi-fader-bg"></div>
                        <div v-text="os" class="flex-grow px-2"></div>
                    </div>
                </div>
            </div>
            <div v-text="getDateTime()" class="text-xs text-grey pr-2 py-2"></div>
        </div>
    </div>
</template>

<script>
import { UAParser } from 'ua-parser-js';
import datetime from 'date-and-time';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
import mixin from './../../mixin.js';

export default {
    name: 'v-login-history-item',
    mixins: [
        mixin,
    ],
    props: {
        useragent: {
            type: String,
        },
        ip: {
            type: String,
        },
        datetime: {
            type: String,
        }
    },
    data() {
        return {
            device: '',
            model: '',
            os: '',
            version: '',
            date_time: '',
        }
    },
    mounted() {
        TimeAgo.addLocale(en);

        let ua = new UAParser(this.useragent),
            ta = new TimeAgo();

        this.device = ua.getDevice().type;
        this.model = ua.getDevice().model;
        this.os = ua.getOS().name + ' ' + ua.getOS().version;
        this.date_time = ta.format(new Date(this.datetime), 'rounded-minute');
    },
    methods: {
        getIcon() {
            return this.device.toLowerCase() == 'mobile' ? require("@/assets/icons/papsi_device_mobile.svg") : require("@/assets/icons/papsi_device_tablet.svg");
        },
        getDateTime() {
            let now = new Date(),
                week_ago = now.setDate(now.getDate() - 5),
                date = new Date(this.datetime);

            return date <= week_ago ? datetime.format(date, 'MMM D') : this.date_time;
        },
    }
}
</script>

<style scoped>
.papsi-fader-bg {
    background-color: rgba(255, 255, 255, 0.20);
}
</style>