<template>
    <div class="w-full bg-grey-darkest">
        <ContentWrapperControl nobottom>
            <div class="py-4">
                <div v-for="index in pages" 
                    v-bind:key="index" 
                    v-bind:class="{ 'mb-4' : (index != pages) }">
                    <img v-bind:src="getPageSource(index)" class="block mx-auto shadow" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
    },
    data() {
        return {
            pages: 10,
        }
    },
    mounted() {
        let title = this.lang('proposed.amendments');

        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
            menu: false,
        });
    },
    methods: {
        getPageSource(index) {
            return this.getMediaPath("/proposed-amendments/" + index + ".jpg");
        },
    },
}
</script>
