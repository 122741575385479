<template>
    <div class="bg-white p-4 rounded shadow mb-4">
        <div v-text="title" class="pb-3 text-md font-hairline"></div>
        <div class="border-t text-sm">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "v-setting-card",
    props: {
        title: {
            type: String
        }
    }
}
</script>