<template>
    <ContentWrapperControl class="mt-4">
        <SettingCardControl v-bind:title="lang('account')">
            <SettingCardItemControl v-bind:value="username" 
                v-bind:title="lang('username')"
                icon="papsi_setting_username.svg"
                href="/settings/username" />
            <SettingCardItemControl v-bind:value="password" 
                v-bind:title="lang('password')"
                icon="papsi_setting_password.svg"
                href="/settings/password" />
            <SettingCardItemControl v-bind:title="lang('member.info')" 
                icon="papsi_memdata.svg"
                href="/settings/info" />
        </SettingCardControl>
        <SettingCardControl v-bind:title="lang('contacts')">
            <SettingCardItemControl v-bind:value="mobile"
                v-bind:title="lang('mobile')"
                icon="papsi_setting_mobile.svg"
                href="/settings/mobile" />
            <SettingCardItemControl v-bind:value="email"
                v-bind:title="lang('email')"
                icon="papsi_setting_email.svg"
                href="/settings/email" />
        </SettingCardControl>
        <SettingCardControl v-bind:title="lang('security')">
            <SettingCardItemControl v-bind:title="lang('authentication')"
                icon="papsi_setting_auth.svg"
                href="/settings/security/authentication" />
            <SettingCardItemControl v-bind:title="lang('deactivate.account')"
                icon="papsi_setting_deactivate.svg"
                href="/settings/deactivate" />
        </SettingCardControl>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SettingCardControl from './../../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../../components/controls/SettingCardItemControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    data() {
        return {
            username: '',
            password: '',
            mobile: '',
            email: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('settings'),
            state = this.$store.state;

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        this.username = state.username;
        this.password = state.password;
        this.mobile = state.mobile;
        this.email = state.email;
    },
}
</script>