<template>
    <div>
        <OtpModalControl @cancel="cancel" 
            @showloader="showLoader"
            @hideloader="hideLoader"
            @resend="resend"
            @matches="matches"
            v-bind:show="show_otp"
            v-bind:email="email"
            v-bind:mobile="mobile"
            v-bind:name="name"
            v-bind:otp="otp"
            v-bind:pin="pin"
            v-bind:type="type" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <AlertControl @closes="closed"
                    v-bind:show="display" 
                    v-bind:theme="theme">
                    {{ message }}
                </AlertControl>
                <div class="mb-4 pb-4 border-b">{{ lang('are.you') }} <span v-text="name" class="font-bold"></span>?</div>
                <div v-text="lang('it.seems.that.you.have.lost.your.password.verify.your.identity.by.providing.the.one.time.password.we.will.be.sending.to.your.email.or.mobile.number')" class="text-sm text-grey-dark leading-normal pb-4"></div>
                <div class="mb-4">
                    <div class="border flex flex-col text-xs">
                        <div class="border-b flex">
                            <div v-text="lang('username')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div v-text="username" class="flex-grow p-2 truncate text-grey-dark"></div>
                        </div>
                        <div v-show="email != null" class="flex border-b">
                            <div v-text="lang('email')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div v-text="email" class="flex-grow p-2 truncate text-grey-dark"></div>
                        </div>
                        <div v-show="mobile.length != 0" class="flex">
                            <div v-text="lang('mobile')" class="border-r papsi-table-field-w p-2 font-bold bg-grey-lightest"></div>
                            <div v-text="mobile" class="flex-grow p-2 truncate text-grey-dark"></div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="sendOtp">
                            {{ lang('proceed') }}
                        </ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="back" theme="error">
                            {{ lang('not.me') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTP from './../../services/OTP.js';
import OtpModalControl from './../../components/controls/OTPModalControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        OtpModalControl,
    },
    data() {
        return {
            name: '',
            username: '',
            email: '',
            mobile: '',
            show_otp: false,
            otp: null,
            pin: null,
            type: 1,
            display: false,
            message: '',
            theme: 'error',
        }
    },
    mounted() {
        let title = this.lang('verify.account');

        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        this.name = this.$store.state.forgot.name || '';
        this.username = this.$store.state.forgot.username || '';
        this.email = this.$store.state.forgot.email || '';
        this.mobile = this.$store.state.forgot.mobile || '';

        if(this.name.length == 0 && this.username.length == 0 && this.email.length == 0 && this.mobile.length == 0) {
            this.$router.push('/forgot-password');
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        closed() {
            this.display = false;
        },
        cancel() {
            this.show_otp = false;
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        sendOtp() {
            this.$emit('showloader');
            let that = this;

            OTP.request(this.type, this.name, this.email, this.mobile, this.pin).then(function(response) {
                that.otp = response.data.data.otp;
                that.pin = response.data.data.pin;
                that.$emit('hideloader');
                that.show_otp = true;
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.$emit('hideloader');
            });
        },
        resend(otp) {
            this.otp = otp;
        },
        matches() {
            this.$router.push('/forgot-password/update');
        }
    }
}
</script>

<style scoped>
.papsi-table-field-w {
    width: 100px;
}
</style>