<template>
    <div>
        <PromptControl @ok="ok"
            @cancel="canceled"
            @closed="promptClosed"
            v-bind:show="show"
            v-bind:title="lang('discard.changes')"
            v-bind:message="lang('do.you.want.to.discard.all.changes')" />
        <OTPModalControl v-bind:desc="lang('enter.the.one.time.password.we.have.sent.to.your.email')" 
            @cancel="cancel" 
            @showloader="showLoader"
            @hideloader="hideLoader"
            @matches="matches"
            @resend="resend"
            @terminate="terminate"
            v-bind:show="show_otp" 
            v-bind:otp="otp"
            v-bind:pin="pin"
            v-bind:email="value"
            v-bind:name="name"
            v-bind:type="type"
            keeploader />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <AlertControl v-bind:show="display" 
                    v-bind:theme="theme"
                    @closes="closed">
                    {{ message }}
                </AlertControl>
                <div class="mb-3">
                    <TextControl @typing="typing" 
                        v-bind:value="value"
                        name="email" 
                        type="text" />
                </div>
                <div>
                    <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                        {{ lang('update') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import OTP from './../../services/OTP.js';
import OTPModalControl from './../../components/controls/OTPModalControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        OTPModalControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            disabled: true,
            display: false,
            theme: 'error',
            message: '',
            otp: null,
            pin: null,
            show_otp: false,
            name: null,
            type: 6,
            show: false,
            href: null,
            changed: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('edit.email');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.show = true;

                return false;
            }
        });

        this.value = this.$store.state.email || '';
        this.name = this.$store.state.first_name;
    },
    methods: {
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        promptClosed() {
            this.show = false;
        },
        canceled() {
            this.show = false;
        },
        typing(obj) {
            this.value = obj.value;
            this.enableButton();
        },
        enableButton() {
            this.disabled = this.value.length == 0 || (this.value == this.$store.state.email);
            this.changed = this.value != this.$store.state.email;
        },
        update() {
            let that = this,
                uuid = this.$store.state.uuid;

            this.$emit('showloader');
            User.validateEmail(uuid, this.value).then(function() {
                that.requestOtp();
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.$emit('terminate', error.response.status);

                if(error.response.status != 403) {
                    that.$emit('hideloader');
                }
            });
        },
        requestOtp() {
            let that = this;

            OTP.request(6, this.name, this.value, null, this.pin).then(function(response) {
                that.otp = response.data.data.otp;
                that.pin = response.data.data.pin;
                that.$emit('hideloader');
                that.showOtp();
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.$emit('terminate', error.response.status);

                if(error.response.status != 403) {
                    that.$emit('hideloader');
                }
            });
        },
        showOtp() {
            this.show_otp = true;
        },
        closed() {
            this.display = false;
        },
        cancel() {
            this.show_otp = false;
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        terminate(status) {
            this.$emit('terminate', status);
        },
        matches() {
            let that = this,
                uuid = this.$store.state.uuid;

            this.show_otp = false;
            User.updateEmail(uuid, this.value).then(function() {
                that.$store.state.email = that.value;
                that.theme = 'success';
                that.message = that.lang('email.was.successfully.updated');
                that.display = true;
                that.changed = false;
                that.disabled = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.disabled = true;
                that.$emit('terminate', error.response.status);

                if(error.response.status != 403) {
                    that.$emit('hideloader');
                }
            });
        },
        resend(otp) {
            this.otp = otp;
        },
    }
}
</script>