<template>
    <div v-bind:class="{ 'mr-2' : !last }" class="flex-1 overflow-hidden rounded">
        <div class="bg-yellow-dark text-center font-bold text-2xl papsi-countdown-h">
            <div v-text="value" class="py-3"></div>
        </div>
        <div v-text="title" class="p-2 bg-primary text-white text-sm text-center truncate"></div>
    </div>
</template>

<script>
export default {
    name: 'v-countdown-item',
    props: {
        title: {
            type: String,
        },
        value: {
            type: String,
            default: '00',
        },
        last: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style scoped>
.papsi-countdown-h {
    height: 48px;
}
.papsi-hithere {
  animation: hithere 1s ease infinite;
}
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}
</style>