<template>
    <div v-on:click="redirect" class="py-3 border-b flex w-full">
        <div></div>
        <div class="flex-grow flex">
            <div class="w-icon h-icon mr-2">
                <img v-bind:src="require('@/assets/icons/' + icon)" />
            </div>
            <div class="flex-grow h-icon text-md font-bold pr-2 flex justify-content items-center">
                <span v-text="title"></span>
            </div>
            <div class="text-sm h-icon text-grey truncate flex justify-content items-center">
                <span v-text="value"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "v-setting-card-item",
    props: {
        title: {
            type: String,
        },
        value: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
        },
        href: {
            type: String,
        },
    },
    methods: {
        redirect() {
            if(this.href != null) {
                this.$router.push(this.href);
            }
        },
    },
}
</script>