<template>
    <div class="w-full h-screen flex flex-col">
        <OTPModalControl @cancel="cancel"
            @matches="matches"
            @showloader="showLoader"
            @hideloader="hideLoader"
            @resend="resend"
            v-bind:type="otp_type"
            v-bind:show="show_otp"
            v-bind:otp="otp"
            v-bind:name="name"
            v-bind:mobile="mobile"
            v-bind:email="email"
            v-bind:pin="pin"
            keeploader />
        <MessageBoxControl @closed="closes"
            v-bind:show="display"
            v-bind:message="message"
            v-bind:title="lang('login.failed')" />
        <div class="h-screen w-full flex flex-col">
            <div class="bg-primary py-6 w-full text-center">
                <div class="pt-6">
                    <img class="papsi-main-logo" v-bind:src="image" />
                </div>
                <div v-text="lang('welcome.ka.may.ari')" class="text-white font-bold text-xl mt-6 uppercase"></div>
                <div v-text="lang('serving.the.afp.since.1981')" class="text-white font-thin text-md italic mt-1"></div>
            </div>
            <div class="w-full pb-6">
                <div v-show="freezed" class="bg-yellow-lighter shadow mb-3 p-3 text-red text-xs">
                    {{ lang('login.is.freezed.for') }} <span v-text="seconds"></span> {{ lang('seconds') }}.
                </div>
                <ContentWrapperControl class="mt-6">
                    <div class="mb-4">
                        <TextControl @typing="typing"
                            v-bind:value="username"
                            v-bind:placeholder="lang('username')"
                            type="text"
                            name="username" />
                    </div>
                    <div class="mb-4">
                        <TextControl @typing="typing"
                            v-bind:value="password"
                            v-bind:placeholder="lang('password')"
                            type="password"
                            name="password" />
                    </div>
                    <div class="flex mb-4 text-md">
                        <div class="flex-1">
                            <router-link to="/signup" class="text-primary no-underline">
                                {{ lang('sign.up') }}
                            </router-link>
                        </div>
                        <div class="flex-1 text-right">
                            <router-link to="/help" class="text-primary no-underline">
                                {{ lang('need.help') }}
                            </router-link>
                        </div>
                    </div>
                    <div>
                        <ButtonControl v-on:click="login" v-bind:disabled="disabled">
                            {{ lang('log.in') }}
                        </ButtonControl>
                    </div>
                </ContentWrapperControl>
            </div>
            <div class="px-4 text-center leading-normal text-grey-darkest text-xs w-full papsi-curr-version">
                <span v-text="version"></span>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import MessageBoxControl from './../components/controls/MessageBoxControl.vue';
import OTP from './../services/OTP.js';
import OTPModalControl from './../components/controls/OTPModalControl.vue';
import mixin from './../mixin.js';
import TextControl from './../components/controls/TextControl.vue';
import User from './../services/User.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        MessageBoxControl,
        OTPModalControl,
        TextControl,
    },
    data() {
        return {
            attempt: 0,
            display: false,
            message: '',
            disabled: true,
            username: '',
            password: '',
            freezed: false,
            reset: 60,
            seconds: 60,
            delay: 4000,
            uuid: null,
            hash: null,
            otp_type: 10,
            image: '',
            version: '',
            email: '',
            mobile: '',
            name: '',
            otp: '',
            pin: '',
            show_otp: false,
            expire: 0,
        }
    },
    mounted() {
        this.$emit('hidenavbar');
        this.version = this.lang('version') + ' ' + process.env.VUE_APP_VERSION;
        this.image = this.getMediaPath('/logo/papsi-mobile.jpg');

        if(this.$store.state.login) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        login() {
            this.$emit('showloader');
            let that = this;

            User.authenticate(this.username, this.password).then(function(response) {
                that.loadData(response);
            }).catch(function(error) {
                that.password = '';
                that.attempt++;
                that.message = error.response.data.message;
                that.display = true;
                
                if(that.attempt == 3) {
                    that.freezed = true;
                    that.freezeLogin();
                }

                that.$emit('hideloader');
            });
        },
        freezeLogin() {
            let that = this,
                t = setInterval(function() {
                    that.seconds--;

                    if(that.seconds == 0) {
                        that.seconds = this.reset;
                        that.attempt = 0;
                        that.freezed = false;
                        clearInterval(t);
                    }

            }, 1000);
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.enableButton();
        },
        enableButton() {
            this.disabled = (this.username.length == 0 || this.password.length == 0) || this.freezed;
        },
        loadData(response) {
            let data = response.data,
                config = data.data.config,
                that = this,
                has_2fa = false;

            this.uuid = data.data.uuid;
            this.hash = data.data.hash;

            config.forEach(function(item) {
                if(item.type == 2 && item.status) {
                    has_2fa = true;
                }
                else if(item.type == 1 && item.status) {
                    that.expire = -1;
                }
            });

            if(has_2fa) {
                this.email = data.data.email;
                this.mobile = data.data.mobile;
                this.name = data.data.name;
                this.sendOTP();
            }
            else {
                this.makeSession();
            }
        },
        sendOTP() {
            let that = this;

            OTP.request(this.otp_type, this.name, this.email, this.mobile, this.pin).then(function(response) {
                let data = response.data.data;

                that.otp = data.otp;
                that.pin = data.pin;
                that.$emit('hideloader');
                that.show_otp = true;
            }).catch(function(error) {
                that.password = '';
                that.attempt++;
                that.message = error.response.data.message;
                that.display = true;
                
                if(that.attempt == 3) {
                    that.freezed = true;
                    that.freezeLogin();
                }

                that.$emit('hideloader');
            });
        },
        makeCookies() {
            if(!this.$cookies.isKey('uuid') && !this.$cookies.isKey('hash')) {
                let expire = this.expire == -1 ? "1y" : "0";

                this.$cookies.set('uuid', this.uuid, expire);
                this.$cookies.set('hash', this.hash, expire);
            }
        },
        makeSession() {
            let that = this;

            User.makeSession(this.uuid, this.hash, this.attempt + 1).then(function(response) {
                that.$store.state.ip = response.data.data.ip;
                that.loadUserAgent(response.data.data.agent);
                that.makeCookies();
                that.loadUserInfo();
            }).catch(function(error) {
                that.password = '';
                that.attempt++;
                that.message = error.response.data.message;
                that.display = true;
                
                if(that.attempt == 3) {
                    that.freezed = true;
                    that.freezeLogin();
                }

                that.$emit('hideloader');
            });
        },
        loadUserInfo() {
            let that = this;

            User.getInfo(that.uuid).then(function(response) {
                let state = that.$store.state;

                state.uuid = that.uuid;
                state.hash = that.hash;
                state.login = true;
                that.loadUserStates(response.data.data);
                that.$emit('login');
                that.$emit('hideloader');
                that.$router.push('/dashboard');
            }).catch(function(error) {
                that.password = '';
                that.attempt++;
                that.message = error.response.data.message;
                that.display = true;

                that.$emit('hideloader');
            });
        },
        closes() {
            this.display = false;
        },
        cancel() {
            this.show_otp = false;
            this.password = '';
        },
        matches() {
            this.makeSession();
        },
        resend(otp) {
            this.otp = otp;
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
    },
    watch: {
        attempt(n) {
            this.freezed = n >= 3;
        },
    },
}
</script>

<style scoped>
.papsi-main-logo {
    width: 100%;
    max-width: 200px;
}
.papsi-curr-version {
    bottom: 0px;
}
</style>