import service from './../service.js';

export default {
    getConfig(uuid) {
        return service.get('/election/' + uuid);
    },

    register(uuid) {
        return service.post('/election/' + uuid);
    },

    start(uuid) {
        return service.post('/election/' + uuid + '/start');
    },

    candidates(category, type = null) {
        let url = '/election/candidates/' + category;

        if(type != null) {
            url += '/' + type;
        }

        return service.get(url);
    },

    vote(uuid, data = []) {
        return service.post('/election/' + uuid + '/vote', {
            data: data,
        });
    },

    getBallot(ballot) {
        return service.get('/election/ballot/' + ballot);
    },

    updateMock(uuid, action) {
        return service.post('/election/' + uuid + '/mock', {
            action: action,
        });
    },

    attachOtp(uuid, otp) {
        return service.put('/election/' + uuid, {
            otp: otp,
        });
    },

    submitPoll(uuid, value) {
        return service.post('/election/poll/' + uuid, {
            vote: value,
        });
    },   
}