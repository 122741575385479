<template>
    <div class="bg-white rounded shadow mb-3 overflow-hidden">
        <div class="flex w-full">
            <div class="bg-primary px-1">
                <div class="w-menu">
                    <img src="@/assets/icons/papsi_explore.svg" class="block mx-auto mt-2" />
                </div>
            </div>
            <div class="flex-grow">
                <div class="p-3">
                    <div v-text="title" class="w-full border-b pb-2 text-sm font-bold"></div>
                    <div v-text="address" class="text-xs italic text-grey-dark w-full pt-2 leading-normal"></div>
                    <div class="pt-4 text-xs">
                        <span v-text="contact1" class="block bg-yellow-dark p-1 px-2 inline mr-1"></span>
                        <span v-show="contact2 != null" v-text="contact2" class="block bg-yellow-dark p-1 px-2 inline"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-branch-item',
    props: {
        title: {
            type: String,
        },
        address: {
            type: String,
        },
        contact1: {
            type: String,
        },
        contact2: {
            type: String,
            default: null,
        },
    },
}
</script>