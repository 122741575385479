<template>
    <div class="flex">
        <CountdownItemControl v-bind:value="days" v-bind:title="lang('day')" />
        <CountdownItemControl v-bind:value="hour" v-bind:title="lang('hour')" />
        <CountdownItemControl v-bind:value="min" v-bind:title="lang('min')" />
        <CountdownItemControl v-bind:value="sec" v-bind:title="lang('sec')" last />
    </div>
</template>

<script>
import CountdownItemControl from './CountdownItemControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-countdown',
    mixins: [
        mixin,
    ],
    components: {
        CountdownItemControl,
    },
    data() {
        return {
            days: '00',
            hour: '00',
            min: '00',
            sec: '00',
        }
    },
    mounted() {
        let that = this,
            state = that.$store.state,
            date = (new Date(!state.election.voting_started ? state.election.voting_start : state.election.voting_end)).getTime(),
            ended = (new Date(state.election.voting_end)).getTime();

        function tick() {
            let loop = setTimeout(function() {
                let now = new Date().getTime(),
                    distance = (date - now),
                    days = Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    sec = Math.floor((distance % (1000 * 60)) / 1000);

                    that.days = (days < 10 ? '0' + days : days).toString();
                    that.hour = (hour < 10 ? '0' + hour : hour).toString();
                    that.min = (min < 10 ? '0' + min : min).toString();
                    that.sec = (sec < 10 ? '0' + sec : sec).toString();

                    if(days <= 0 && hour <= 0 && min <= 0 && sec <= 0 && now <= ended) {
                        that.$emit('starts');
                        date = (new Date(state.election.voting_end)).getTime();
                    }
                    else {
                        that.$emit('ticking');
                    }

                    if(now > ended) {
                        that.$emit('ended');
                        clearTimeout(loop);
                    }
                    else {
                        clearTimeout(loop);
                        tick();
                    }

            }, 1000);
        }

        tick();
    }
}
</script>
