<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 rounded shadow">
            <AlertControl @closes="close"
                v-bind:show="display"
                v-bind:theme="theme">
                {{ message }}
            </AlertControl>
            <div class="mb-4">
                <TextboxControl @typing="typing" 
                    v-bind:value="password1" 
                    v-bind:placeholder="lang('new.password')"
                    type="password"
                    name="password1"
                    required
                    strength />
            </div>
            <div class="mb-4">
                <TextboxControl @typing="typing" 
                    @strong="strong"
                    v-bind:value="password2"
                    v-bind:placeholder="lang('confirm.password')"
                    type="password" 
                    name="password2"
                    required
                    nohint />
            </div>
            <div>
                <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                    {{ lang('update') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextboxControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        TextboxControl,
    },
    data() {
        return {
            password1: '',
            password2: '',
            disabled: true,
            message: '',
            display: false,
            theme: 'error',
            passed: false,
        }
    },
    mounted() {
        let title = this.lang('update.password');

        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        if(this.$store.state.forgot.uuid == null) {
            this.$router.push('/forgot-password');
        }
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        close() {
            this.display = false;
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.disabled = this.password1.length == 0 || this.password2.length == 0 || !this.passed;
        },
        update() {
            let uuid = this.$store.state.forgot.uuid,
                that = this;

            that.$emit('showloader');
            User.updatePassword(uuid, null, this.password1, this.password2, 0).then(function() {
                that.$cookies.set('forgot_password', 'success');
                that.$emit('hideloader');
                that.$store.state.forgot.uuid = null;
                that.$store.state.forgot.name = null;
                that.$store.state.forgot.mobile = null;
                that.$store.state.forgot.email = null;
                that.$router.push('/forgot-password/success');
            }).catch(function(error) {
                that.password1 = '';
                that.password2 = '';
                that.message = error.response.data.message;
                that.theme = 'error';
                that.display = true;
                that.$emit('hideloader');
            });
        }
    }
}
</script>