<template>
    <div v-on:click="toggle" v-bind:class="[ color ]" class="rounded-full papsi-switch">
        <div v-bind:class="{ 'papsi-selected' : status }" class="w-icon h-icon rounded-full shadow-md bg-white"></div>
    </div>
</template>

<script>
export default {
    name: 'v-switch-control',
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            status: false,
            color: 'bg-grey-light',
        }
    },
    mounted() {
        this.status = this.checked;
        this.color = this.status ? 'bg-green' : 'bg-grey-light';
    },
    methods: {
        toggle() {
            if(!this.disabled) {
                if(this.status) {
                    this.status = false;
                    this.color = 'bg-grey-light';
                }
                else {
                    this.status = true;
                    this.color = 'bg-green';
                }

                this.$emit('toggle', this.status);
            }
        }
    },
    watch: {
        checked(n) {
            this.status = n;
            this.color = this.status ? 'bg-green' : 'bg-grey-light';
        },
    },
}
</script>

<style scoped>
.papsi-switch {
    width: 48px;
}
.papsi-selected {
    margin-left: 24px;
}
</style>