<template>
    <div v-show="value != null" class="py-3 border-b flex text-sm">
        <div class="h-icon w-icon">
            <img v-bind:src="require('@/assets/icons/' + icon)" />
        </div>
        <div class="flex-grow px-2 font-bold flex justify-content items-center">
            <span v-text="title"></span>
        </div>
        <div class="text-grey-darker truncate flex justify-content items-center">
            <span v-text="value"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-info-bar-control',
    props: {
        title: {
            type: String,
        },
        value: {
            type: String,
        },
        icon: {
            type: String,
        },
    },
}
</script>