<template>
    <ContentWrapperControl class="mt-4">
        <SettingCardControl v-bind:title="lang('government.id')">
            <SettingCardItemControl v-on:click="goToEditTin"
                v-bind:value="tin"
                v-bind:title="lang('tin')"
                icon="papsi_tax.svg" />
        </SettingCardControl>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SettingCardControl from './../../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../../components/controls/SettingCardItemControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    data() {
        return {
            tin: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('member.info');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        this.tin = this.$store.state.tin;
    },
    methods: {
        goToEditTin() {
            this.$router.push('/settings/info/tin');
        },
    },
}
</script>