<template>
    <div>
        <ModalControl @closed="closed" 
            v-bind:show="display" 
            v-bind:title="lang('qr.code')" 
            theme="success"
            dismiss>
                <div class="w-full text-center">
                    <div class="w-full">
                        <img v-bind:src="getCode()" class="block" />
                    </div>
                    <div v-text="$store.state.qr" class="text-grey-dark text-xs leading-normal bg-grey-lighter p-1"></div>
                </div>
        </ModalControl>
        <ModalControl @closed="closed"
            v-bind:show="mev.show"
            v-bind:title="mev.title"
            v-bind:theme="mev.theme"
            dismiss>
            <div v-text="mev.message" class="text-grey-dark text-sm leading-normal"></div>
        </ModalControl>
        <div class="bg-primary w-full pt-6">
            <div class="flex pb-6 px-3">
                <div class="h-menu w-menu">
                    <img v-on:click="back" src="@/assets/icons/papsi_back.svg" class="block" />
                </div>
                <div class="flex-grow">
                    <div class="bg-white rounded mx-auto mt-6 shadow-md papsi-avatar overflow-hidden">
                        <img v-if="loaded" v-on:click="openQr" v-bind:src="getCode()" class="block" />
                        <img v-if="!loaded" v-bind:src="getLoader()" class="block" />
                    </div>
                    <div v-text="name" class="text-center text-white text-2xl font-bold pt-2"></div>
                    <div class="flex justify-center mt-2">
                        <div v-text="memtype" class="text-center text-white font-bold p-2 rounded text-sm papsi-fader-bg"></div>
                    </div>
                </div>
                <div class="h-menu w-menu">
                    <img v-on:click="openSidebar" src="@/assets/icons/papsi_menu.svg" class="block" />
                </div>
            </div>
            <div class="max-w-limit mx-auto px-4">
                <div class="bg-white py-2 rounded-t">
                    <div class="px-3 flex">
                        <div class="flex-1 border-r text-sm">
                            <div v-text="lang('account.number')" class="font-bold truncate"></div>
                            <div v-text="account_no" class="text-primary text-xs pt-1"></div>
                        </div>
                        <div class="flex-1 text-right text-sm">
                            <div v-text="lang('serial.number')" class="font-bold truncate"></div>
                            <div v-text="getSerial()" class="text-primary text-xs pt-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContentWrapperControl>
            <div class="bg-white py-1 mb-3 border-b rounded-b shadow"></div>
            <div class="bg-white p-4 mb-3 rounded shadow">
                <div v-text="lang('info')" class="text-md pb-2 text-grey-darkest"></div>
                <div class="border-t">
                    <InfoBarControl v-if="gender != null" v-bind:value="gender" v-bind:icon="getGenderIcon()" v-bind:title="lang('gender')" />
                    <InfoBarControl v-bind:value="tin" v-bind:title="lang('tin')" icon="papsi_tax.svg" />
                    <InfoBarControl v-bind:value="status" v-bind:title="lang('status')" icon="papsi_status.svg" />
                    <InfoBarControl v-bind:value="regular" v-bind:title="lang('membership')" icon="papsi_memtype.svg" />
                    <InfoBarControl v-bind:value="memdate" v-bind:title="lang('member.since')" icon="papsi_date.svg" />
                </div>
            </div>
            <div class="bg-white p-4 mb-3 rounded shadow">
                <div v-text="lang('contacts')" class="text-md pb-2 text-grey-darkest"></div>
                <div class="border-t">
                    <InfoBarControl v-bind:value="mobile" v-bind:title="lang('mobile')" icon="papsi_setting_mobile.svg" />
                    <InfoBarControl v-bind:value="email" v-bind:title="lang('email')" icon="papsi_setting_email.svg" />
                </div>
            </div>
            <div class="bg-white p-4 mb-3 rounded shadow">
                <div v-text="lang('account')" class="text-md pb-2 text-grey-darkest"></div>
                <div class="border-t">
                    <InfoBarControl v-bind:value="username" v-bind:title="lang('username')" icon="papsi_setting_username.svg" />
                    <InfoBarControl v-bind:value="date" v-bind:title="lang('registered')" icon="papsi_date.svg" />
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ContentWrapperControl from '../components/controls/ContentWrapperControl.vue';
import InfoBarControl from '../components/controls/InfoBarControl.vue';
import ModalControl from '../components/controls/ModalControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        InfoBarControl,
        ModalControl,
    },
    data() {
        return {
            username: '',
            account_no: '',
            serial_no: '',
            name: '',
            gender: '',
            tin: '',
            status: '',
            regular: '',
            memdate: null,
            memtype: null,
            email: '',
            mobile: '',
            date: '',
            display: false,
            loaded: false,
            size: 400,
            color: 'text-primary',
            mev: {
                title: '',
                message: '',
                theme: 'success',
                show: false,
            }
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('hidenavbar');

        let state = this.$store.state;

        if(state.login) {
            this.name = state.first_name + ' ' + state.last_name;
            this.gender = state.gender;
            this.regular = state.regular != null ? (state.regular ? this.lang('regular') : this.lang('associate')) : null;
            this.username = state.username;
            this.status = state.status;
            this.account_no = state.account_no;
            this.serial_no = state.serial_no;
            this.email = state.email;
            this.mobile = state.mobile;
            this.memdate = state.memdate;
            this.memtype = state.memtype;
            this.tin = state.tin;
            this.date = state.registered;
            this.color = state.election.migs ? 'text-primary' : 'text-red-dark';

            this.loadQr();
        }
    },
    methods: {
        openSidebar() {
            this.$emit('opensidebar');
        },
        back() {
            this.$router.back();
        },
        getGenderIcon() {
            return this.gender.toLowerCase() == 'male' ? 'papsi_gender_male.svg' : 'papsi_gender_female.svg';
        },
        openMevModal() {
            this.mev.title = this.getStanding();
            this.mev.message = this.getMevMessage();
            this.mev.theme = this.$store.state.election.migs ? 'success' : 'error';
            this.mev.show = true;
        },
        getMevMessage() {
            let state = this.$store.state.election;

            if(state.migs) {
                return 'You are a member entitled to vote.';
            }
            else {
                if(state.signed) {
                    return 'You have signed a representative form.';
                }
                else {
                    return 'You are not part of members entitled to vote.';
                }
            }
        },
        loadQr() {
            let url = this.getCode(),
                that = this,
                img = new Image();
                img.src = url;
                img.onload = function() {
                    let t = setTimeout(function() {
                        that.loaded = true;
                        clearTimeout(t);
                    }, 1000);
                };
        },
        getSerial() {
            return this.serial_no != null ? this.serial_no : '??????';
        },
        closed() {
            this.display = false;
            this.mev.show = false;
        },
        getCode() {
            return "https://quickchart.io/qr?text=" + this.$store.state.qr + "&size=" + this.size;
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
        openQr() {
            this.display = true;
        },
        getStanding() {
            let state = this.$store.state;

            return state.election.migs ? 'MEV' : 'Non-MEV';
        },
    },
    watch: {
        top(n) {
            if(n > this.threshold) {
                this.navbar = true;
            }
            else {
                this.navbar = false;
            }
        }
    }
}
</script>

<style scoped>
.papsi-avatar {
    width: 140px;
    height: 140px;
}
.papsi-fader-bg {
    background-color: rgba(255, 255, 255, 0.15);
}
</style>