<template>
    <div>
        <MessageBoxControl @closed="messageClosed"
            v-bind:show="message.show"
            v-bind:title="message.title"
            v-bind:message="message.message"
            v-bind:theme="message.theme" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white shadow rounded overflow-hidden">
                <div>
                    <img v-bind:src="image" class="w-full block" />
                </div>
                <div v-show="!countdown">
                    <img v-bind:src="getLoader()" class="block mx-auto" />
                </div>
                <div v-show="!ended && countdown" class="p-3 pb-2">
                    <div v-show="!ended" class="pb-2 text-base font-bold">
                        <span v-text="sub"></span>
                    </div>
                    <div>
                        <CountdownControl @ticking="ticking"
                            @starts="electionStarts" 
                            @ended="electionEnds" />
                    </div>
                </div>
                <div v-show="started && ended" class="p-3 text-center text-grey-dark text-sm">
                    {{ lang('registration.has.officially.closed') }}
                </div>
                <div v-show="show && !ended && !registered && countdown" class="px-3 pb-3">
                    <ButtonControl v-bind:theme="theme" v-on:click="register">
                        {{ label }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import CountdownControl from './../components/controls/CountdownControl.vue';
import Election from './../services/Election.js';
import MessageBoxControl from './../components/controls/MessageBoxControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        CountdownControl,
        MessageBoxControl,
    },
    data() {
        return {
            username: '',
            image: '',
            show: false,
            label: null,
            theme: 'success',
            href: '/ga/poll',
            sub: null,
            message: {
                title: null,
                theme: 'error',
                message: '',
                show: false,
            },
            started: false,
            ended: true,
            voted: false,
            registered: false,
            can_vote: false,
            success: false,
            countdown: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        this.$emit('shownavbar', {
            type: 'primary',
        });

        this.image = this.getMediaPath('/ad/special_general_assembly.jpg');
        this.username = this.$store.state.username;
        this.started = this.$store.state.election.voting_started;
        this.ended = this.$store.state.election.voting_ended;
        this.voted = this.$store.state.election.voted;
        this.registered = this.$store.state.election.registered;
        this.can_vote = this.$store.state.election.can_vote;
        this.sub = this.lang('will.start.in');
        this.label = this.lang('register');

        if(this.started && !this.ended) {
            if(!this.voted && this.can_vote) {
                this.show = true;
            }

            this.sub = this.lang('will.end.in');
        }
    },
    methods: {
        ticking() {
            this.countdown = true;
        },
        register() {
            let that = this;

            this.$emit('showloader');
            Election.register(this.$store.state.uuid).then(function() {
                if(!that.$store.state.election.can_vote) {
                    that.$store.state.election.registered = true;
                    that.success = true;
                    that.message.title = that.lang('congratulations');
                    that.message.theme = 'success';
                    that.message.message = that.lang('you.have.successfully.registered.to.the.special.general.assembly');
                    that.message.show = true;
                }
                else {
                    that.$router.push(that.href);
                }

                that.$emit('hideloader');
            })
            .catch(function(error) {
                that.message.title = that.lang('error');
                that.message.theme = 'error';
                that.message.message = error.response.data.message;
                that.message.show = true;
                that.$emit('terminate', error.response.status);

                if(error.response.status != 403) {
                    that.$emit('hideloader');
                }
            });
        },
        electionStarts() {
            this.sub = this.lang('will.end.in');
            this.show = true;
        },
        electionEnds() {
            this.ended = true;
            this.show = false;
        },
        messageClosed() {
            this.message.show = false;

            if(this.success && this.can_vote) {
                this.$router.push(this.href);
            }
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
    },
}
</script>
