<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 rounded shadow">
            <div v-text="lang('your.password.has.been.successfully.updated')" class="pb-4 text-sm text-grey-dark"></div>
            <div>
                <ButtonControl href="/">
                    {{ lang('log.in') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
    },
    mounted() {
        let title = this.lang('updated.successfully');

        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: false,
        });

        if(this.$cookies.isKey('forgot_password')) {
            this.$cookies.remove('forgot_password');
        }
        else {
            this.$router.push('/forgot-password');
        }
    }
}
</script>