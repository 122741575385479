<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 shadow rounded">
            <div v-text="lang('how.do.you.feel.about.this')" class="leading-normal font-bold mb-6"></div>
            <div class="mb-4">
                <RatingControl @toggled="toggled"
                    @submitted="exit" 
                    v-bind:submit="submitted" 
                    v-bind:type="rating_type" />
            </div>
            <div class="mb-3">
                <ButtonControl v-on:click="submit" v-bind:disabled="disabled">
                    {{ lang('submit') }}
                </ButtonControl>
            </div>
            <div class="leading-normal text-grey-darkest text-center">
                {{ lang('or') }} <span v-on:click="exit" v-text="lang('skip')" class="text-primary"></span>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import RatingControl from './../../components/controls/RatingControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        RatingControl,
    },
    data() {
        return {
            rating_type: 2,
            disabled: true,
            submitted: false,
        }
    },
    mounted() {
        let title = this.lang('ratings'),
            election = this.$store.state.election;

        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: false,
            menu: false,
        });

        if(!election.voted || election.rated) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        toggled() {
            this.disabled = false;
        },
        submit() {
            this.submitted = true;
            this.$emit('showloader');
        },
        exit() {
            this.$store.state.election.rated = true;
            this.$emit('hideloader');
            this.$router.push('/dashboard');
        }
    }
}
</script>