export default {
    'account'                                                                                                                                               : 'Account',
    'account.created'                                                                                                                                       : 'Account Created',
    'account.no'                                                                                                                                            : 'Account No.',
    'account.number'                                                                                                                                        : 'Account Number',
    'and'                                                                                                                                                   : 'and',
    'approved'                                                                                                                                              : 'Approved',
    'are.you'                                                                                                                                               : 'Are you',
    'are.you.sure.you.want.to.deactivate.your.account'                                                                                                      : 'Are you sure you want to deactivate your account?',
    'are.you.sure.you.want.to.leave'                                                                                                                        : 'Are you sure you want to leave?',
    'associate'                                                                                                                                             : 'Associate',
    'authentication'                                                                                                                                        : 'Authentication',
    'authentication.settings'                                                                                                                               : 'Authentication Settings',
    'bad'                                                                                                                                                   : 'Bad',
    'cancel'                                                                                                                                                : 'Cancel',
    'confirm.password'                                                                                                                                      : 'Confirm Password',
    'congratulations'                                                                                                                                       : 'Congratulations',
    'contacts'                                                                                                                                              : 'Contacts',
    'contact.us'                                                                                                                                            : 'Contact Us',
    'continue'                                                                                                                                              : 'Continue',
    'create.an.account'                                                                                                                                     : 'Create an account',
    'dashboard'                                                                                                                                             : 'Dashboard',
    'day'                                                                                                                                                   : 'Day',
    'deactivate.account'                                                                                                                                    : 'Deactivate Account',
    'disapproved'                                                                                                                                           : 'Disapproved',
    'discard.changes'                                                                                                                                       : 'Discard Changes',
    'do.you.want.to.discard.all.changes'                                                                                                                    : 'Do you want to discard all changes?',
    'edit.email'                                                                                                                                            : 'Edit Email',
    'edit.mobile'                                                                                                                                           : 'Edit Mobile',
    'edit.password'                                                                                                                                         : 'Edit Password',
    'edit.tin'                                                                                                                                              : 'Edit TIN',
    'edit.username'                                                                                                                                         : 'Edit Username',
    'email'                                                                                                                                                 : 'Email',
    'email.was.successfully.updated'                                                                                                                        : 'Email was successfully updated.',
    'end.user.license.agreement'                                                                                                                            : 'End User License Agreement',
    'enter.the.one.time.password.we.have.sent.to.your.email'                                                                                                : 'Enter the one time password we have sent to your email.',
    'enter.the.one.time.password.we.have.sent.to.your.mobile.number'                                                                                        : 'Enter the one time password we have sent to your mobile number.',
    'error'                                                                                                                                                 : 'Error',
    'first.name'                                                                                                                                            : 'First Name',
    'forgot.password'                                                                                                                                       : 'Forgot Password',
    'forgot.password.is.freezed.for'                                                                                                                        : 'Forgot password is freezed for',
    'gender'                                                                                                                                                : 'Gender',
    'good'                                                                                                                                                  : 'Good',
    'government.id'                                                                                                                                         : 'Government ID',
    'help'                                                                                                                                                  : 'Help',
    'help.and.support'                                                                                                                                      : 'Help & Support',
    'hour'                                                                                                                                                  : 'Hour',
    'how.do.you.feel.about.this'                                                                                                                            : 'How do you feel about this?',
    'i.agree.with.the'                                                                                                                                      : 'I agree with the',
    'info'                                                                                                                                                  : 'Info',
    'it.seems.that.you.have.lost.your.password.verify.your.identity.by.providing.the.one.time.password.we.will.be.sending.to.your.email.or.mobile.number'   : 'It seems that you have lost your password. Verify your identity by providing the one time password we will be sending to your email or number.',
    'join'                                                                                                                                                  : 'Join',
    'keep.current.session.in.this.device'                                                                                                                   : 'Keep current session in this device.',
    'last.name'                                                                                                                                             : 'Last Name',
    'log.in'                                                                                                                                                : 'Log In',
    'login.automatically'                                                                                                                                   : 'Login Automatically',
    'login.failed'                                                                                                                                          : 'Login Failed',
    'login.is.freezed.for'                                                                                                                                  : 'Login is freezed for',
    'login.history'                                                                                                                                         : 'Login History',
    'log.out'                                                                                                                                               : 'Log Out',
    'luzon'                                                                                                                                                 : 'Luzon',
    'maintenance.mode'                                                                                                                                      : 'Maintenance Mode',
    'member.info'                                                                                                                                           : 'Member Info',
    'membership'                                                                                                                                            : 'Membership',
    'member.since'                                                                                                                                          : 'Member Since',
    'middle.name'                                                                                                                                           : 'Middle Name',
    'min'                                                                                                                                                   : 'Min',
    'mindanao'                                                                                                                                              : 'Mindanao',
    'mobile'                                                                                                                                                : 'Mobile',
    'mobile.no'                                                                                                                                             : 'Mobile No.',
    'mobile.was.successfully.updated'                                                                                                                       : 'Mobile was successfully updated.',
    'moderate'                                                                                                                                              : 'Moderate',
    'need.help'                                                                                                                                             : 'Need Help?',
    'new.password'                                                                                                                                          : 'New Password',
    'not.found'                                                                                                                                             : 'Not Found',
    'nothing.to.show'                                                                                                                                       : 'Nothing to show.',
    'not.me'                                                                                                                                                : 'Not Me',
    'ok'                                                                                                                                                    : 'Ok',
    'old.password'                                                                                                                                          : 'Old Password',
    'one.time.password'                                                                                                                                     : 'One Time Password',
    'operating.system'                                                                                                                                      : 'Operating System',
    'or'                                                                                                                                                    : 'or',
    'others'                                                                                                                                                : 'Others',
    'our.company'                                                                                                                                           : 'Our Company',
    'our.team.is.currently.doing.some.maintenance.work.in.our.systems.to.serve.you.better'                                                                  : 'Our team is currently doing some maintenance work in our systems to serve you better.',
    'pafcpic'                                                                                                                                               : 'PAFCPIC',
    'password'                                                                                                                                              : 'Password',
    'password.was.successfully.updated'                                                                                                                     : 'Password was successfully updated.',
    'please.enter.either.your.account.number.serial.number.username.email.or.mobile.number.to.find.your.account'                                            : 'Please enter either your account number, serial number, username, email or mobile number to find your account.',
    'please.login.to.your.account'                                                                                                                          : 'Please login to your account.',
    'proceed'                                                                                                                                               : 'Proceed',
    'profile'                                                                                                                                               : 'Profile',
    'proposed.amendments'                                                                                                                                   : 'Proposed Amendments',
    'privacy.policy'                                                                                                                                        : 'Privacy Policy',
    'qr.code'                                                                                                                                               : 'QR Code',
    'ratings'                                                                                                                                               : 'Ratings',
    'register'                                                                                                                                              : 'Register',
    'registered'                                                                                                                                            : 'Registered',
    'registration.has.officially.closed'                                                                                                                    : 'Registration has officially closed.',
    'regular'                                                                                                                                               : 'Regular',
    'reload'                                                                                                                                                : 'Reload',
    'require.one.time.password.before.login'                                                                                                                : 'Require one time password before login.',
    'resend'                                                                                                                                                : 'Resend',
    'satellite.offices'                                                                                                                                     : 'Satellite Offices',
    'sec'                                                                                                                                                   : 'Sec',
    'seconds'                                                                                                                                               : 'Seconds',
    'security'                                                                                                                                              : 'Security',
    'see.login.history'                                                                                                                                     : 'See login history',
    'serial.no'                                                                                                                                             : 'Serial No.',
    'serial.number'                                                                                                                                         : 'Serial Number',
    'serving.the.afp.since.1981'                                                                                                                            : 'Serving the AFP since 1981',
    'settings'                                                                                                                                              : 'Settings',
    'sign.up'                                                                                                                                               : 'Sign Up',
    'skip'                                                                                                                                                  : 'Skip',
    'something.went.wrong'                                                                                                                                  : 'Something went wrong.',
    'status'                                                                                                                                                : 'Status',
    'submit'                                                                                                                                                : 'Submit',
    'terms.and.policies'                                                                                                                                    : 'Terms & Policies',
    'tin'                                                                                                                                                   : 'TIN',
    'two.factor.authentication'                                                                                                                             : 'Two Factor Authentication',
    'untitled'                                                                                                                                              : 'Untitled',
    'update'                                                                                                                                                : 'Update',
    'update.password'                                                                                                                                       : 'Update Password',
    'updated.successfully'                                                                                                                                  : 'Updated Successfully',
    'use.eight.or.more.characters.with.a.mix.of.letters.numbers.and.special.characters'                                                                     : 'Use eight or more characters with a mix of letters, numbers and special characters.',
    'username'                                                                                                                                              : 'Username',
    'username.must.be.at.least.six.characters.and.must.not.contain.spaces.and.special.characters'                                                           : 'Username must be at least six characters and must not contain spaces and special characters.',
    'username.was.successfully.updated'                                                                                                                     : 'Username was successfully updated.',
    'validate'                                                                                                                                              : 'Validate',
    'very.bad'                                                                                                                                              : 'Very Bad',
    'very.good'                                                                                                                                             : 'Very Good',
    'verify.account'                                                                                                                                        : 'Verify Account',
    'version'                                                                                                                                               : 'version',
    'visayas'                                                                                                                                               : 'Visayas',
    'voting.ends.in'                                                                                                                                        : 'voting ends in',
    'voting.has.officially.closed'                                                                                                                          : 'Voting has officially closed.',
    'voting.starts.in'                                                                                                                                      : 'voting starts in',
    'welcome.ka.may.ari'                                                                                                                                    : 'Welcome Ka-May-Ari',
    'we.ll.be.right.back'                                                                                                                                   : 'We\'ll be right back!',
    'will.end.in'                                                                                                                                           : 'will end in',
    'will.start.in'                                                                                                                                         : 'will start in',
    'you.can.resend.again.after'                                                                                                                            : 'You can resend again after',
    'you.have.successfully.registered.to.the.special.general.assembly'                                                                                      : 'You have successfully registered to the Special General Assembly.',
    'your.account.has.been.created'                                                                                                                         : 'Your account has been created.',
    'your.password.has.been.successfully.updated'                                                                                                           : 'Your password has been successfully updated.',
}