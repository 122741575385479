<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 mb-3 rounded shadow">
            <div class="border-t">
                <SwitchSettingControl @terminate="terminate" 
                    @toggle="toggle"
                    @success="success"
                    v-bind:title="lang('login.automatically')"
                    v-bind:description="lang('keep.current.session.in.this.device')"
                    :type="1" />
                <SwitchSettingControl @terminate="terminate"
                    @toggle="toggle"
                    @success="success" 
                    v-bind:title="lang('two.factor.authentication')"
                    v-bind:description="lang('require.one.time.password.before.login')"
                    :type="2" />
            </div>
        </div>
        <div v-on:click="goToLoginHistory" 
            v-text="lang('see.login.history')" 
            class="bg-grey-light rounded p-3 text-grey-darkest text-center text-sm"></div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import SwitchSettingControl from './../../components/controls/SwitchSettingControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SwitchSettingControl,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('authentication.settings');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    },
    methods: {
        terminate(status) {
            this.$emit('terminate', status);
        },
        toggle() {
            this.$emit('showblinder');
        },
        success() {
            this.$emit('hideblinder');
        },
        goToLoginHistory() {
            this.$router.push('/settings/security/login-history');
        },
    },
}
</script>