import { UAParser } from 'ua-parser-js';
import English from './locale/en.js';

export default {
    data() {
        return {
            locales: {
                en: English,
            }
        }
    },
    methods: {
        getMediaPath(path) {
            return process.env.VUE_APP_MEDIA_URL + 'images' + path;
        },
        lang(id, lang = null) {
            let store = this.$store.state,
                language = lang || store.lang,
                locale = this.locales[language],
                keys = Object.keys(locale);

                if(keys.indexOf(id) !== -1) {
                    return locale[id];
                }

            return id;
        },
        loadUserAgent(userAgent) {
            let ua = new UAParser(userAgent),
                state = this.$store.state;

            state.user_agent.browser.name = ua.getBrowser().name;
            state.user_agent.browser.version = ua.getBrowser().version;
            state.user_agent.device.model = ua.getDevice().model;
            state.user_agent.device.type = ua.getDevice().type;
            state.user_agent.device.vendor = ua.getDevice().vendor;
            state.user_agent.engine.name = ua.getEngine().name;
            state.user_agent.engine.version = ua.getEngine().version;
            state.user_agent.os.name = ua.getOS().name;
            state.user_agent.os.version = ua.getOS().version;
            state.user_agent.cpu = ua.getCPU().architecture;
        },
        resetUserAgent() {
            let state = this.$store.state;

            state.user_agent.browser.name = null;
            state.user_agent.browser.version = null;
            state.user_agent.device.model = null;
            state.user_agent.device.type = null;
            state.user_agent.device.vendor = null;
            state.user_agent.engine.name = null;
            state.user_agent.engine.version = null;
            state.user_agent.os.name = null;
            state.user_agent.os.version = null;
            state.user_agent.cpu = null;
        },
        loadUserStates(data) {
            let state = this.$store.state;

            state.email = data.account.email;
            state.mobile = data.account.mobile;
            state.first_name = data.info.first_name;
            state.last_name = data.info.last_name;
            state.middle_name = data.info.middle_name;
            state.gender = data.info.gender;
            state.account_no = data.info.account_no;
            state.serial_no = data.info.serial_no;
            state.key = data.key;
            state.qr = data.qr;
            state.lang = data.lang;
            state.username = data.account.username;
            state.password = data.account.password;
            state.status = data.info.status;
            state.registered = data.account.registered;
            state.regular = data.info.regular;
            state.memtype = data.info.membership_type;
            state.memdate = data.info.membership_date;
            state.tin = data.info.tin;

            state.election.voted = data.election.voted;
            state.election.can_vote = data.election.can_vote;
            state.election.voting_started = data.election.voting_started;
            state.election.voting_start = data.election.vote_start;
            state.election.voting_ended = data.election.voting_ended;
            state.election.voting_end = data.election.vote_end;
            state.election.registered = data.election.registered;
    
            data.config.forEach(function(item) {
              state.config.push(item);
            });
        },
        resetUserData() {
            let state = this.$store.state;

            state.login = false;
            state.ip = null;
            state.uuid = null;
            state.hash = null;
            state.email = null;
            state.mobile = null;
            state.account_no = null;
            state.serial_no = null;
            state.first_name = null;
            state.last_name = null;
            state.middle_name = null;
            state.gender = null;
            state.regular = false;
            state.memdate = null;
            state.memtype = null;
            state.tin = null;
            state.key = null;
            state.qr = null;
            state.lang = 'en';
            state.config.splice(0, state.config.length);
            state.username = null;
            state.password = null;
            state.registered = null;

            state.election.voted = false;
            state.election.can_vote = false;
            state.election.voting_started = false;
            state.election.voting_start = null;
            state.election.voting_ended = false;
            state.election.voting_end = null;
            state.election.registered = false;
            state.election.rated = false;
            state.election.done = false;

            state.config.splice(0, state.config.length);

            this.resetUserAgent();
        },
    }
}