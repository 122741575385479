<template>
    <ContentWrapperControl>
        <div v-show="!loaded" class="bg-white rounded shadow p-4 mt-4">
            <div class="flex justify-content items-center w-full">
                <img v-bind:src="getLoader()" class="block mx-auto" />
            </div>
        </div>
        <div v-show="loaded">
            <div v-if="empty" v-text="lang('nothing.to.show')" class="text-center mt-4 p-4 text-sm text-grey-dark bg-white rounded shadow"></div>
            <div v-if="!empty" class="mt-4">
                <LoginHistoryItemControl v-for="(item, index) in items"
                    v-bind:key="index"
                    v-bind:ip="item.ip"
                    v-bind:useragent="item.user_agent"
                    v-bind:datetime="item.datetime" />
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import LoginHistoryItemControl from './../../components/controls/LoginHistoryItemControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        LoginHistoryItemControl,
    },
    data() {
        return {
            items: [],
            loaded: false,
            empty: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('login.history');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        this.loadLogs();
    },
    methods: {
        loadLogs() {
            let that = this;

            User.authLogs(this.$store.state.uuid).then(function(response) {
                let data = response.data.data;
                
                data.forEach(function(item) {
                    that.items.push(item);
                });

                that.loaded = true;
            }).catch(function(error) {
                that.empty = true;
                that.loaded = true;
                that.$emit('terminate', error.response.status);
            });
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
    }
}
</script>