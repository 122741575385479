<template>
    <div>
        <div v-show="freezed" class="bg-yellow-lighter p-3 text-red text-xs shadow">
            {{ lang('forgot.password.is.freezed.for') }} <span v-text="delay"></span> {{ lang('seconds') }}.
        </div>
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <AlertControl @closes="close"
                    v-bind:show="display"
                    v-bind:theme="theme">
                    {{ message }}
                </AlertControl>
                <div v-text="lang('please.enter.either.your.account.number.serial.number.username.email.or.mobile.number.to.find.your.account')" class="text-sm text-grey-dark pb-3"></div>
                <div class="mb-3">
                    <TextboxControl @typing="typing" 
                        v-bind:value="value" 
                        name="info" />
                </div>
                <div>
                    <ButtonControl v-on:click="find" v-bind:disabled="disabled">
                        {{ lang('continue') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextboxControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        TextboxControl,
    },
    data() {
        return {
            attempt: 0,
            disabled: true,
            freezed: false,
            display: false,
            value: '',
            theme: 'error',
            message: '',
            delay: 60,
        }
    },
    mounted() {
        let title = this.lang('forgot.password');

        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    },
    methods: {
        close() {
            this.display = false;
        },
        typing(obj) {
            this.value = obj.value;
            this.disabled = obj.value.length == 0 || this.freezed;
        },
        find() {
            this.$emit('showloader');
            let that = this;

            User.forgotPassword(this.value).then(function(response) {
                that.attempt = 0;
                that.freezed = false;

                let state = that.$store.state,
                    data = response.data.data;

                state.forgot.uuid = data.uuid;
                state.forgot.name = data.name;
                state.forgot.username = data.username;
                state.forgot.email = data.email;
                state.forgot.mobile = data.mobile;

                that.$emit('hideloader');
                that.$router.push('/forgot-password/verify');
            }).catch(function(error) {
                that.attempt++;
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.value = '';
                that.$emit('hideloader');
            });
        },
        freeze() {
            let that = this,
                t = setInterval(function() {
                    that.delay--;

                    if(that.delay == 0) {
                        that.attempt = 0;
                        that.freezed = false;
                        that.delay = 60;
                        that.display = false;
                        clearInterval(t);
                    }
                }, 1000);
        },
    },
    watch: {
        attempt(n) {
            if(n >= 3) {
                this.freezed = true;
                this.freeze();
            }
        }
    }
}
</script>