<template>
    <div>
        <MessageBoxControl @closed="done"
            v-bind:show="show"
            v-bind:title="title"
            v-bind:message="message"
            v-bind:theme="theme" />
        <PromptControl @ok="ok"
            @cancel="close"
            @close="close"
            v-bind:show="prompt"
            v-bind:title="lang('discard.changes')"
            v-bind:message="lang('are.you.sure.you.want.to.leave')" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-6 mb-3 rounded shadow text-sm leading-normal text-justify">
                <div class="mb-4">Dear Fellow Member,</div>
                <div class="mb-4">The Board of Directors is proposing for approval of the attached amendments to PAFCPIC's Articles of Cooperation and By-laws.</div>
                <div class="mb-6 mt-6 text-center underline px-6">
                    <router-link v-bind:to="read" class="text-primary">
                        Read the Proposed Amendments to the PAFCPIC Articles of Cooperation and By-laws
                    </router-link>
                </div>
                <div class="mb-4 font-bold">Justification: To conform with the provisions of RA 9520 or the Philippine Cooperative Code of 2008 and its Implementing Rules and Regulations; and to correct typographical and grammatical error.</div>
                <div class="mb-4">To cast your vote, you may check the appropriate box below.</div>
                <div class="mb-6">Very truly yours,</div>
                <div class="w-logo"><img v-bind:src="signature" /></div>
                <div class="font-bold">BGEN FRANCISCO M PAREDES (RET)</div>
                <div class="mb-4 italic">Chairperson of the Board</div>
                <div class="border-t mt-6"></div>
                <div class="mb-4 mt-6">I hereby cast my vote on the proposed amendments to the PAFCPIC Articles of Cooperation and By-laws, as indicated below with a check mark:</div>
                <div class="mb-4 flex font-bold text-xs uppercase">
                    <div class="flex-1">
                        <CheckBoxControl @toggle="toggleApproved" v-bind:checked="approved">
                            {{ lang('approved') }}
                        </CheckBoxControl>
                    </div>
                    <div class="flex-1">
                        <CheckBoxControl @toggle="toggleDisapproved" v-bind:checked="disapproved">
                            {{ lang('disapproved') }}
                        </CheckBoxControl>
                    </div>
                </div>
                <div class="border-t mt-6 mb-4"></div>
                <div>
                    <ButtonControl v-on:click="send" v-bind:disabled="disabled">
                        {{ lang('submit') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import CheckBoxControl from './../../components/controls/CheckboxControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        CheckBoxControl,
        ContentWrapperControl,
        MessageBoxControl,
        PromptControl,
    },
    data() {
        return {
            success: false,
            show: false,
            title: '',
            message: '',
            theme: 'error',
            signature: null,
            approved: false,
            disapproved: false,
            value: false,
            disabled: true,
            content: false,
            prompt: false,
            href: null,
            leave: false,
            read: '/ga/amendments',
        }
    },
    mounted() {
        this.signature = this.getMediaPath('/misc/paredes-signature.jpg');
        this.$emit('checkauth');
        this.$emit('nofooter', true);

        let title = this.lang('proposed.amendments'),
            state = this.$store.state,
            that = this;

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
            menu: false,
        });

        if(state.election.voted || !state.election.can_vote || !state.election.voting_started || state.election.voting_ended) {
            this.$router.push('/dashboard');
        }

        onBeforeRouteLeave((to) => {
            if(!that.$store.state.election.voted && to.path != this.read && !that.leave) {
                that.prompt = true;
                that.href = to.fullPath;

                return false;
            }
        });
    },
    methods: {
        ok() {
            this.leave = true;
            this.$router.push(this.href);
        },
        close() {
            this.prompt = false;
        },
        toggleApproved(state) {
            this.approved = state;
            
            if(state) {
                this.disapproved = !state;
            }

            this.updateValue();
        },
        toggleDisapproved(state) {
            this.disapproved = state;
            
            if(state) {
                this.approved = !state;
            }

            this.updateValue();
        },
        updateValue() {
            if(this.approved && !this.disapproved) {
                this.value = true;
                this.disabled = false;
            }
            else if(!this.approved && this.disapproved) {
                this.value = false;
                this.disabled = false;
            }
            else  {
                this.disabled = true;
            }
        },
        send() {
            let that = this;

            this.$emit('showloader');
            Election.submitPoll(this.$store.state.uuid, this.value).then(function() {
                that.$store.state.election.registered = true;
                that.$store.state.election.voted = true;
                that.success = true;
                that.theme = 'success';
                that.title = 'Success';
                that.message = 'Your vote has been successfully cast. Thank you for participating to this year\'s Special General Assembly.';
                that.show = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.success = false;
                that.theme = 'error';
                that.title = 'Error';
                that.message = error.response.data.message;
                that.show = true;
                that.$emit('hideloader');
            });
        },
        done() {
            if(this.success) {
                this.$router.push('/ga/poll/rating');
            }
            else {
                this.$router.push('/dashboard');
            }
        },
    }
}
</script>
