<template>
    <ContentWrapperControl class="mt-4">
        <SettingCardControl v-bind:title="lang('help.and.support')">
            <SettingCardItemControl v-show="!$store.state.login" 
                v-bind:title="lang('forgot.password')" 
                href="/forgot-password"
                icon="papsi_help_forgot.svg" />
            <SettingCardItemControl v-bind:title="lang('contact.us')"
                href="/contact-us"
                icon="papsi_help_contact.svg" />
        </SettingCardControl>
        <SettingCardControl v-bind:title="lang('terms.and.policies')">
            <SettingCardItemControl v-bind:title="lang('end.user.license.agreement')" 
                href="/end-user-license-agreement"
                icon="papsi_help_terms.svg" />
            <SettingCardItemControl v-bind:title="lang('privacy.policy')" 
                href="/privacy-policy"
                icon="papsi_help_privacy.svg" />
        </SettingCardControl>
    </ContentWrapperControl>
</template>

<script>
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import SettingCardControl from './../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../components/controls/SettingCardItemControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapperControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    mounted() {
        let title = this.lang('need.help');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    },
}
</script>