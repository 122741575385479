<template>
    <div>
        <OTPModalControl @cancel="cancel"
            @resend="resend"
            @matches="matches"
            @showloader="showLoader"
            @hideloader="hideLoader"
            v-bind:show="show_otp"
            v-bind:otp="otp"
            v-bind:pin="pin"
            v-bind:name="first_name"
            v-bind:mobile="getMobile()"
            v-bind:email="email"
            v-bind:type="type" 
            keeploader />
        <ContentWrapperControl>
            <div class="bg-white p-6 mt-4 rounded shadow mb-4 leading-normal">
                <AlertControl @closes="closed"
                    v-bind:show="display" 
                    v-bind:theme="theme" 
                    dismiss>
                    {{ message }}
                </AlertControl>
                <div class="mb-4">
                    <TextControl @typing="typing"
                        v-bind:value="last_name"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('last.name')"
                        type="text" 
                        name="last_name" 
                        uppercase
                        required />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="first_name"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('first.name')"
                        type="text" 
                        name="first_name" 
                        uppercase
                        required />
                </div>
                <div class="mb-4">
                    <div class="flex">
                        <div class="flex-1 mr-3">
                            <TextControl @typing="typing" 
                                v-bind:value="account_no"
                                v-bind:disabled="freezed || serial_no.length != 0"
                                v-bind:placeholder="lang('account.no')"
                                :maxlength="6" 
                                type="text" 
                                name="account_no" 
                                uppercase />
                        </div>
                        <div class="flex-1">
                            <TextControl @typing="typing" 
                                v-bind:value="serial_no"
                                v-bind:disabled="freezed || account_no.length != 0"
                                v-bind:placeholder="lang('serial.no')"
                                :maxlength="8" 
                                type="text" 
                                name="serial_no" 
                                uppercase />
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="mobile"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('mobile.no')"
                        type="mobile" 
                        name="mobile"
                        required />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing"
                        v-bind:value="email"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('email')"
                        type="text" 
                        name="email" />
                </div>
                <div class="mb-4">
                    <div>
                        <TextControl @typing="typing" 
                            v-bind:value="username"
                            v-bind:disabled="freezed"
                            v-bind:placeholder="lang('username')"
                            type="text" 
                            name="username"
                            required />
                    </div>
                    <div v-text="lang('username.must.be.at.least.six.characters.and.must.not.contain.spaces.and.special.characters')" class="mt-1 text-xs text-grey-dark italic"></div>
                </div>
                <div class="mb-4">
                    <div>
                        <TextControl @typing="typing" 
                            v-bind:value="password1"
                            v-bind:disabled="freezed"
                            v-bind:placeholder="lang('password')"
                            type="password" 
                            name="password1" 
                            required
                            strength />
                    </div>
                    <div v-text="lang('use.eight.or.more.characters.with.a.mix.of.letters.numbers.and.special.characters')" class="mt-1 text-xs text-grey-dark italic"></div>
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        @strong="strong"
                        v-bind:value="password2"
                        v-bind:disabled="freezed"
                        v-bind:placeholder="lang('confirm.password')"
                        type="password" 
                        name="password2"
                        nohint 
                        required />
                </div>
                <div class="mb-4 text-xs leading-normal text-grey-darkest">
                    <CheckboxControl @toggle="toggle" v-bind:disabled="freezed" v-bind:checked="agree">
                        <div>
                            {{ lang('i.agree.with.the') }} <router-link to="/end-user-license-agreement" class="no-underline text-primary font-bold">
                                {{ lang('end.user.license.agreement') }}
                            </router-link>
                            {{ lang('and') }} <router-link to="/privacy-policy" class="no-underline text-primary font-bold">
                                {{ lang('privacy.policy') }}
                            </router-link>.
                        </div>
                    </CheckboxControl>
                </div>
                <div>
                    <ButtonControl v-on:click="join" v-bind:disabled="disabled">
                        {{ lang('join') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import AlertControl from './../components/controls/AlertControl.vue';
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import CheckboxControl from './../components/controls/CheckboxControl.vue';
import OTPModalControl from './../components/controls/OTPModalControl.vue';
import TextControl from './../components/controls/TextControl.vue';
import OTP from './../services/OTP.js';
import User from './../services/User.js';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        CheckboxControl,
        OTPModalControl,
        TextControl,
    },
    data() {
        return {
            type: 2,
            disabled: true,
            last_name: '',
            first_name: '',
            account_no: '',
            serial_no: '',
            username: '',
            email: '',
            mobile: '',
            password1: '',
            password2: '',
            display: false,
            message: '',
            otp: null,
            pin: null,
            show_otp: false,
            agree: false,
            freezed: false,
            theme: 'error',
            passed: false,
        }
    },
    mounted() {
        let title = this.lang('create.an.account');

        this.$emit('checkauth', false);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        })

        let state = this.$store.state.registration,
            that = this;

        this.last_name = state.last_name;
        this.first_name = state.first_name;
        this.account_no = state.account_no;
        this.serial_no = state.serial_no;
        this.username = state.username;
        this.email = state.email;
        this.mobile = state.mobile;
        this.password1 = state.password1;
        this.password2 = state.password2;
        this.agree = state.agree;

        onBeforeRouteLeave((to) => {
            let exclude = [
                    'create-account-page',
                    'privacy-policy-page',
                    'end-user-license-agreement-page',
                ];

            if(exclude.indexOf(to.name) === -1) {
                let store = that.$store.state.registration;

                store.last_name = '';
                store.first_name = '';
                store.account_no = '';
                store.serial_no = '';
                store.username = '';
                store.email = '';
                store.mobile = '';
                store.password1 = '';
                store.password2 = '';
                store.agree = false;
            }
        });
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        toggle(n) {
            this.agree = n;
            this.$store.state.registration.agree = n;
            this.disableButton();
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.$store.state.registration[obj.name] = obj.value;
            this.disableButton();
        },
        disableButton() {
            this.disabled = (this.last_name.length == 0 ||
                this.first_name.length == 0 ||
                (this.account_no.length == 0 && this.serial_no.length == 0) ||
                this.username.length == 0 ||
                this.mobile.length == 0 ||
                this.password1.length == 0 ||
                this.password2.length == 0 ||
                !this.agree ||
                !this.passed
            );
        },
        closed() {
            this.display = false;
        },
        join() {
            this.$emit('showloader');
            let that = this;

            User.validate({
                first_name: this.first_name,
                last_name: this.last_name,
                account_no: this.account_no.toUpperCase(),
                serial_no: this.serial_no.toUpperCase(),
                email: this.email,
                mobile: this.getMobile(),
                username: this.username,
                password1: this.password1,
                password2: this.password2,
            }).then(function() {
                that.requestOTP();
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.password1 = '';
                that.password2 = '';
                that.agree = false;
                that.$emit('hideloader');
            });
        },
        requestOTP() {
            let that = this;

            OTP.request(this.type, this.first_name, this.email, this.getMobile(), this.pin).then(function(response) {
                that.otp = response.data.data.otp;
                that.pin = response.data.data.pin;
                that.$emit('hideloader');
                that.show_otp = true;
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.$emit('hideloader');
            });
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        cancel() {
            this.show_otp = false;
        },
        resend(otp) {
            this.otp = otp;
        },
        getMobile() {
            return this.mobile.length != 0 ? '+63' + this.mobile : null;
        },
        matches() {
            let that = this;

            this.display = false;
            this.disabled = true;
            this.freezed = true;

            User.create({
                first_name: this.first_name,
                last_name: this.last_name,
                account_no: this.account_no.toUpperCase(),
                serial_no: this.serial_no.toUpperCase(),
                email: this.email,
                mobile: this.getMobile(),
                username: this.username,
                password1: this.password1,
                password2: this.password2,
            }).then(function() {
                that.$cookies.set("register", that.first_name);

                that.first_name = '';
                that.last_name = '';
                that.account_no = '';
                that.serial_no = '';
                that.username = '';
                that.email = '';
                that.mobile = '';
                that.password1 = '';
                that.password2 = '';
                that.agree = false;
                that.$emit('hideloader');
                that.$router.push('/signup/done');
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.password1 = '';
                that.password2 = '';
                that.agree = false;
                that.$emit('hideloader');
            });
        }
    }
}
</script>